/* 
1-A7C957---167.201.87
2-BC4749----188.71.73
@react-pdf/renderer

*/

/* 
cover carousel */
/* ************* Slider******************* */
* {
  box-sizing: border-box;
}

/* Slideshow container */
.slider-container {
  position: relative;
  width: 100%;
  height: fit-content;
 background-color: rgba(0, 5, 36,0.9);
}

/* Loading Page */

.loadingPage{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background-color: rgb(0, 5, 36);
 
}

.landingLogo{
  display: flex;
  column-gap: 5px;
 justify-content: center;
 color: #A7C957;
}

.landingLogo span{
  color:#BC4749;
}

.landingLoading{
 display: flex;
 justify-content: center;
 margin-top: 20px;
}

/* fin */

/* LoadingTwo */

.loadingPage2 {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background-color: rgba(255, 255, 255, 0.76);

}


/* PAGINATION */

/* pagination */

.pagination{
display: flex;
  justify-content: center;
   border-collapse: collapse;
  
}

.paging{
  display: flex;
  width: fit-content;
  border: 1px solid red;
  margin-bottom: 50px;
  align-items: center;
 background-color: whitesmoke;
border-radius: 25px;
 
}


.pagingItem, .breakPaginate{
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 20px;
  padding: 5px 10px;
border-right: 0.2px solid black;
border-left: 0.2px solid black;
  color: black;
   border-collapse: collapse;
  
  
}

.pagingItem:hover, .breakPaginate:hover{
  background-color: rgb(0, 5, 36);
  color: #D52B1E;
}

.previousPaginate{
 
  color: rgb(0, 5, 36);
  width: 100%;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 0px 5px;
}
.previousPaginate:hover{
  background-color: rgb(0, 5, 36);
  color: white;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.nextPaginate{
color: rgb(0, 5, 36);
  width: 100%;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 0px 5px;
}

.nextPaginate:hover{
  background-color: rgb(0, 5, 36);
  color: white;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}


.activePaginate{
  background-color: #D52B1E;
  color:white;

}



/* fin de pagination */


/* fin  */

/* Style each slide */
.slide {
  display: none;
  width: 100%;
  height: 100%;
}

.photo {
  width: 100%;
  max-height: 600px;
  object-fit: fill;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  background: rgba(0, 5, 36,0.6);
  color: #e56717;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border: none;
  font-weight: bolder;
}

/* Position the "next" button to the right */
.next {
  right: 0;
}

/* On hover, add a semi-transparent black background */
.prev:hover,
.next:hover {
  background-color: rgb(0, 5, 36);
}

/* Caption text */
.caption {
  position: absolute;
  bottom: 8px;
  width: 100%;
  padding: 8px 12px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  top: 10px
}

/* The dots indicator */
.dotsm {
 display: flex;
  justify-content: center;
  align-items: center;
 text-align: center;
height: 30px;
background-color: rgb(0, 5, 36);
  
}

.dotm {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color:#BC4749;
  border-radius: 50%;
  display: inline-block;
  transition: 0.5s;
  color: #7fff00;
  
}

.activem,
.dotm:hover {
  background-color: #7fff00;
  color: red;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

.sliderContainerO{
  position: relative;
}

/* *****************Fin Slider********************* */


.imagesTest{
  width: 500px;
  height: 50px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  list-style: none;
  

}

::-webkit-scrollbar{
  width: 12px;
}
::-webkit-scrollbar-track{
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb{
  background: #BC4749;
  transition: all 0.5s;
  border: 15px;
}
::-webkit-scrollbar-thumb:hover{
  background: #A7C957;
  transition: all 0.5s;
}


body{
  background-color: whitesmoke;
overflow-y: scroll; 

}

a, a:hover,a:active, a:visited,a:link {
  text-decoration: none;
  color: inherit;

}

/* Headrer  */

.navBarO{
  display: flex;
  flex-direction: column;
  width: 100%;
  height:50px;
  position: sticky;
  top: 0;
  background-color: rgb(0, 5, 36);
  z-index: 1;
  
}

.navBar{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  columns: 2;
  column-gap: 1%;
  border-bottom: 2px solid #A7C957;
}

.logo{
  width: 20%;
  height: fit-content;
  display: flex;
  column-gap: 2%;
  color:#A7C957 ;
  margin-left: 2%;
}



.logoN span{
  color: #BC4749;
  font-weight: bolder;
}

.menu{
  display: flex;
  flex-direction: row;
  width: 70%;
  column-gap: 1%;
  justify-content: center;
  
}

.userName{
  color: #A7C957;
  margin-right: 0%;
  display: flex;
  flex-direction: row;
  width: 70%;
  align-self: center;
  justify-content: center;
}

#userString{
  border: 3px solid #A7C957;
  border-radius: 15px;
  padding: 2px;
  cursor: pointer;
  font-weight: bolder;
  transition: all 0.5s;
}

#userString:hover{
  color: white;
  transition: all 0.5s;
  background-color: #BC4749;
  border: 3px solid #BC4749;
}

.userName h3{
  margin-left: 20px;
  
}

#menuBar{
  color: #A7C957;
  cursor: pointer;
  transition: all 0.5s;
  
}

#menuBar:hover{
  color: #BC4749;
  transition: all 0.5s;
}

#menuBarClose{
  color: #BC4749;
  cursor: pointer;
  transition: all 0.5s;
}

#menuBarClose:hover{
color: #A7C957;
transition: all 0.5s;
}

.menuOpen{
  width: 25%;
  background-color: white;
  height: fit-content;
  color: rgb(0, 5, 36);
  display: flex;
  position: absolute;
  right: 0;
  padding: 15px;
  top:50px;
  z-index: 1;
  flex-direction: column;
  padding: 20px 20px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  opacity: 0.9;
}

.menuOpen a{
  color: rgb(0, 5, 36);
  transition: all 0.5s;
  font-weight: bolder;
}
.menuOpen a:hover{
  color: #A7C957;
  transition: all 0.5s;
  font-weight: bolder;
}

.slider{
  width: 100%;
  height: 400px;
  
}

.Arrow{
  position: absolute;
}

#arrowRight{
  position: absolute;
  margin-left: 500px;
  margin-top: -40px;
  color: white;
  cursor: pointer;
}

#arrowLeft{
  position: absolute;
  margin-left: -500px;
  margin-top: -40px;
  color:white;
  cursor: pointer;


}

.photoCover{
  width:100%;
  height:350px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  justify-content: center;
  align-content: center;
  display: flex;
  align-items: center;
  justify-items: center;
  
}

.infosCover{
  position: absolute;
 color: white;
  
}

.infosCover h1{
font-size: 60px;
margin-top: 90px;
}

.infosCover p{
  font-size: 25px;
  font-weight: bolder;
}

/* MainPost */

.promoSubscribe{
  width: 100%;
  height: fit-content;
  display: flex;
  column-gap: 2%;
  justify-content: center;

}

.tittlePromo{
font-weight: 900;
}

.agri{
  width: 50%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  margin: 20px 10px;
  border-radius: 20px;
    box-shadow: 4px 4px 7px 2px #A7C957;
    text-align: center;
}

.ache{
  width: 50%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
    margin: 20px 10px;
    border-radius: 20px;
    box-shadow: 4px 4px 7px 2px #BC4749;
    text-align: center;
}

.ButtonPromo{
  display: flex;
  width: 100%;
  height:fit-content;
  margin: 10px 0px;
}

#ButtonPromo{
  padding: 15px 40px;
    color: white;
    background-color: #BC4749;
    outline: none;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.4s;
    margin: 10px auto;
}
#ButtonPromo2{
  padding: 15px 40px;
    color: white;
    background-color: #A7C957;
    outline: none;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.4s;
    margin: 10px auto;
}

#ButtonPromo:hover{
background-color: #A7C957;
}

#ButtonPromo2:hover{
background-color: #BC4749;
}

.postO2{
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
  column-gap: 30px;
  
  
} 
.postO{
  display: flex;
  width: 100%;
  height: fit-content;
  columns: 2;
  margin-bottom: 20px;
  
} 

.postIn{
  display: flex;
  width: 80%;
  height: fit-content;
  padding-top: 10px;
  padding-bottom: 100px;
  flex-wrap: wrap;
  align-content: flex-start;
}

/* MAIN POST CAROUSEL */
.singleFirst{
  display: flex;
  column-gap: 50px;
  width: 360px;
  height: 500px;
  margin-top: 50px;
  margin-left: 30px;
  
}

.singleSecond{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 800px;
  height: fit-content;
  margin-top: 50px;
  font-size: 20px;
  background-color: rgb(0, 5, 36);
  color: white;
  padding: 0px 15px;
  border-radius: 20px;
  box-shadow: 4px 4px 7px 2px #BC4749;
  padding: 80px 10px;
 

}

/* FIN MAIN POST CAROUSEL */

.postOTrue{
  width: 100%;
  height: fit-content;
}


.post{
  display: flex;
  width: 30%;
  height: 480px;
  margin-left: 2%;
  margin-top: 30px;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 4px 4px 7px 2px gray;
  cursor: pointer;
  
}

.postPhoto{
  width: 100%;
  height: 200px;
  display: flex;
  
}

.photoPost{
  width:100%;
  height:100%;
  background-size: cover;
  background-repeat: no-repeat;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  
}

.postInfos{
  display: flex;
  width: 100%;
  height: 200px;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  background-color: rgb(0, 5, 36);;
  color: white;
}

.postInfos h1{
  color: #A7C957;
  font-weight: bolder;
}

#prix{
  color:#BC4749; 
  font-weight: bolder;
  
}

.postReserv{
  display: flex;
  width: 100%;
  height: 120px;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  position:relative;
  
  
}

.postReserv h2{
  margin-top: 10px;
  padding: 5px;
  background-color: #BC4749;
  border-radius: 15px;
  color:white;
}
.postReserv h3{
  
  color:#BC4749;
}

#reserveIcon{
 margin-top: 5px;
 margin-left: 5px;
  position: absolute;
  color:#BC4749;
}

#momentDate{
  color: rgb(0, 5, 36);
  font-weight: bolder;
  
}

/* SideBar */
.sideBarO{
  width: 20%;
  height: fit-content;
  background-color: rgb(0, 5, 36);
  display: flex;
  padding:30px 2%;
  color:white;
  margin-top: 50px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.sideBarIn{
  width: 100%;
  height:100%;
}

.sideBarIn h1{
  color:#BC4749;
}

.typeCulture{
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 40px;
  justify-content: center;
  
}

.typeCulture li h3{
  margin-bottom: 20px;
  cursor: pointer;
  color: #A7C957;
  transition: all 0.5s;
}
.typeCulture li h3:hover{
  color:white;
  transition: all 0.5s;
}


/* Footer */
.footerO{
  width: 100%;
  height: fit-content;
  background-color: rgb(0, 5, 36);
  color: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  margin-top: 30px;
}

.footerIn{
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  
}

.productO{
  width: 100%;
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  
}

.typeFooter{
 color:#BC4749;
 margin-top: 50px;
 padding: 5px 0px;
 border-bottom: 3px solid #A7C957;
 border-top-left-radius: 8px;
 border-top-right-radius: 8px;
 border-bottom-left-radius: 8px;
 border-bottom-right-radius: 8px;

 border-top: 3px solid #A7C957;
}
.product{
  display: flex;
  width: 60%;
  height: 100%;
  
}

.product{
  columns: 2;
  column-gap: 10%;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.productFirst li h3, .productSecond h3{
padding-bottom: 15px;
cursor: pointer;
}
.productFirst li h3:hover, .productSecond h3:hover{
color:#A7C957;
}

.infos{
  display: flex;
  width: 40%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  
}

.infosHead{
  color: #BC4749;
  height: 130px;
  width: fit-content;
  
}

.infosHead h1{
  margin-top: 37px;
  padding: 5px 0px;
 border-bottom: 3px solid #A7C957;
 border-top-left-radius: 8px;
 border-top-right-radius: 8px;
 border-bottom-left-radius: 8px;
 border-bottom-right-radius: 8px;
 border-top: 3px solid #A7C957;
  
}


.infosSociete{
  height: 100px;
  width: 100%;
  margin-top: 5px;
}

.infosSociete li h3{
  padding-bottom: 12px;
  cursor:pointer;
  
}
.infosSociete li h3:hover{
  color:#A7C957;

}

.socialO{
  width: 100%;
  height:100px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  
}

#footerFa{
  color: white;
}

#footerFa:hover{
  color: #A7C957;
}

.credit{
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: center;
  margin-top: 150px;
}

.credit span a{
  color: #A7C957;
}
.credit span a:hover{
  color: #BC4749;
}

#store{
  color: #BC4749;
}



/* SinlePost */

.singlePostO{
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  
}

.singlePhoto{
  display: flex;
  width: 100%;
  height: 400px;
  
}

.imageSingle{
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 15px;
}

.singlePostIn{
  width: 100%;
  height: fit-content;
  columns: 2;
  column-gap: 0%;
  display: flex;
  padding-left: 50px;
  
}

.suppBtn{
  width: 100%;
  height: fit-content;
  position: absolute;
  margin-top: -30px;
  cursor: pointer;
}

#btnPopSupr2:hover{
  background-color: rgb(0, 5, 36);
}

.infosSingle{
  margin-top: 50px;
  width: 80%;
  height: fit-content;
  display: flex;
  column-gap: 40px;
  justify-content: center;
  
}

.infosFourni{
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  border-radius: 20px;
  box-shadow: 4px 4px 7px 2px rgb(0, 5, 36);
  cursor: pointer;

}

.infosFixe{
  width: 30%;
  height: 100%;
  padding: 20px;
 text-align: center;
 
}

.infosFixe ul li h2{
  margin: 25px 0px;
}

.infosReal{
  width: 70%;
  height: 100%;
  padding: 20px;
  color: #BC4749;
  
}

.infosReal ul li h2{
  margin: 25px 0px;
}



.infosClient{
  width: 100%;
  height: 400px;
  display: flex;
  
  
}

.infosUser{
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  column-gap: 0px;
  margin-top: 150px;
  padding: 20px 1%;
  background-color: red;

}

.infoOne{
  width: 30%;
  height: fit-content;
  padding: 20px 5%;
  background-color: green;
}

.infoOne2{
  width: 100%;
  height: 200px;
 
}



.infoOne2 li h3,.infoTwo2 li h3{
padding-top: 20px;
color: rgb(0, 5, 36);
  
}



.infoOne2 li h3 span,.infoTwo2 li h3 span{
  color: #A7C957;
  font-size: 18px;
  font-weight: bolder;
}

.infoTwo{
  width: 70%;
  height: 100%;
  padding: 20px 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  word-wrap: none;
  background-color: pink;
}




.reservation{
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  
}

.reservHeading,.nameIn,.cityIn,.quartierC,.numberC,.btnReserver{
  width: 100%;
  display: flex;
  height: fit-content;
  justify-content: center;
}

.reservHeading h1{
  color: #BC4749;
  font-weight: bolder;
}

.reservHeading{
  margin-top: 40px;
}


#nameC,#quartierC,#numberCl{
  font-size: 24px;
  color: rgb(0, 5, 36);
  border: none;
  width: 15em;
  margin-bottom: 5px;
  padding: 5px;
  border-bottom: 3px solid #A7C957;
  outline: none;
  
}

#nameC{
  margin-top: 10px;
}

#nameC::placeholder,#quartierC::placeholder,#numberCl::placeholder{
color: rgb(0, 5, 36);
}

.cityIn label{
 color: red;
 margin-left: -50%;
 margin-top: 15px;
 margin-right: 20px;
}

#villeIn{
  font-size: 24px;
  color: rgb(0, 5, 36);
  border: none;
  width: 15em;
  margin-bottom: 5px;
  padding: 5px;
  border-right: 3px solid #A7C957;
  border-left: 3px solid #A7C957;
  outline: none;
  margin-right: 0px;
  margin-top: 10px;
}

#quartierC,#numberCl{
  margin-top: 10px;
}

#numberCl::-webkit-outer-spin-button, #numberCl::-webkit-inner-spin-button{
  -webkit-appearance:none;
}

#registerI::-webkit-outer-spin-button,
#registerI::-webkit-inner-spin-button{
-webkit-appearance:none;
}


.btnReserver{
  margin-top: 20px;
}

#btn-reserv{
  padding: 15px 40px;
  color: white;
  background-color: #BC4749;
  outline: none;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.4s;
}

#btn-reserv:hover{
  background-color: #A7C957;
  transition: all 0.4s;
}


#btn-reserv2{
  padding: 15px 40px;
  color: white;
  background-color: #A7C957;
  outline: none;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.4s;
}

#btn-reserv2:hover{
  background-color: #BC4749;
  transition: all 0.4s;
}





.btnSupSing{
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btnSupSing h3{
  margin: 5px 0px;
  font-size: 18px;
}

#protext1{
  color: #BC4749;
  font-size: 43px;
  margin-bottom: 5px;
}
#protext1t{
  color: #7fff00;
  font-size: 30px;
  margin-bottom: 15px;
}

#protext2{
  color: #BC4749;

}

#protext7{
  margin-top: 45px;
}

#protext3{
  color: red;
  font-weight: bolder;
  font-size: 25px;
}

#protext4{
  color: #BC4749;
  font-size: 23px;
  text-decoration: line-through;
  text-decoration-thickness: 3px;
  text-decoration-color: red;
}

.informer{
  margin: 20px 0px;
}

#informerBtn{
  border: none;
  padding: 5px;
  color: white;
  background-color: rgb(0, 5, 36);
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s;
  text-align: center;
}

#informerBtn:hover{
   background-color: #BC4749;
  transition: all 0.5s;
 
}

#protext5{
  color: rgb(0, 5, 36);
  font-size: 23px;
}

#protext5t{
  color: white;
  font-size: 23px;
}

#protext6{
  color: rgb(0, 5, 36);
  font-size: 23px;
}

#protext6t{
  color: white;
  font-size: 23px;
}

#dashContentBtn2{
border: none;
  padding: 5px;
  color: white;
  background-color: #BC4749;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s;
  text-align: center;
}

.userDelete2{
  display: none;
}


/* Publish */

.publishO{
  width: 100%;
  height: 2000px;
  padding-top: 30px;
  color: rgb(0, 5, 36);
}

.nameIn h1{
  color: #BC4749;
}

#asterix{
  color: #BC4749;
  font-weight: bolder;
  font-size: 25px;
}

#dateHeading{
  margin-top: 20px;
}

.datePicker{
  margin-left: 20%;
  margin-top: 10px;
  
}

#imageFerme{
  margin-top: 20px;
}

#inputImage{
  margin-top: 20px;
  margin-left: 20%;
  margin-bottom: 10px;
}


.publishLocate{
  width: 100%;
  height: 50px;
}

#btnLocal{
  margin-top: 30px;
  margin-left: 33%;
  padding: 15px 20px;
  color: white;
  background-color: #A7C957;
  outline: none;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.4s;
}


#btnLocal:hover{
background-color: rgb(0, 5, 36);
transition: all 0.5s;
}

#entCoord{
  margin-top: 15px;
  color: #A7C957;
}

#coordCheck{
  position: absolute;
  margin-top: 5px;
  margin-left: 4px;
}

#infoL{
  margin-top: 10PX;
  
}
#infoL span{
  color: #BC4749;

}

.previewImage{
  height: 300px;
  padding-bottom:30px;
}

.publishLocate{
  width: 100%;
  height: 500px;
  margin-top: 100px;
}

#mapId {
  width: 95vw;
  height: 70vh;
   z-index: 0;
}

/* Map */

#mapId2 {
  width: 95vw;
  height: 90vh;
  z-index: 0;
}

.mapContainer{
  width: 100%;
  height: 600px;
 
}


.popupO{
  width: 320px;
  height: 400px;
  display: flex;
  flex-direction: column;
  
}

.popupOne{
  width: 100%;
  height: 50%;
  color: rgb(0, 5, 36);
  font-weight: bolder;
}

.popImage{
  width: 300px;
  height: 200px;
}

#btnPopSupr,#btnPopSupr2{
  padding: 2px;
  background-color: #BC4749;
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-left: 37%;
  margin-top: 2px;
  cursor: pointer;
}

#popIcon{
  color: #BC4749;
}

#popIcon2{
  color: white;
}

.popupOne span{
  color: #BC4749;
  font-weight: bolder;
}

#popNF{
  color: #A7C957;
  font-weight: bolder;
}

/* AGRICULTEURS */
#eye3 {
  position: absolute;
  color: #A7C957;
  cursor: pointer;
  margin-left: -2%;
  margin-top: 1.5%;
  font-size: 0.8em;
}


#numberCl2 {
  font-size: 24px;
  color: rgb(0, 5, 36);
  border: none;
  width: 13.5em;
  margin-bottom: 5px;
  padding: 5px;
  border-bottom: 3px solid #A7C957;
  outline: none;
 margin-left: 5px;

}


.publishO2 {
  width: 100%;
  height: fit-content;
  padding-top: 30px;
  color: rgb(0, 5, 36);
  display: flex;
  justify-content: center;
}


.publishIn{
  text-align: center;
}


/* FIN DE AGRICULTEURS */

/* Login */

/* LOGIN */

.loginO,
.registerO {
  width: 100%;
  height: 100vh;
}

.loginI,
.registerI {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(167, 201, 87, 0.8), rgba(188, 71, 73, 0.7)), url('/public/1a.jpg');
  background-repeat: no-repeat;
  background-size: cover;

}


.loginForm,
.registerForm {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 480px;
  color: white;
  border: 2px solid white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 4px 4px 70px 20px white;
  padding-top: 20px;
}

.loginForm h1,
.registerForm h1 {
  text-align: center;
  color: #BC4749;
  font-weight: bolder;
}

.labelE,
.labelR,
.labelER {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: -30px;
  margin-top: 40px;
  color: #BC4749;
  font-weight: bolder;
}

.labelP,
.labelPR {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -10px;
  color: #BC4749;
  font-weight: bolder;
}

.email,
.pass,
.loginB,
.register,
.emailR,
.passR,
.loginBR {
  display: flex;
  justify-content: center;
  width: 100%;
}

#emailI,
#passiI,
#loginBI,
#registerI,
#emailIR,
#passiIR,
#loginBIR {
  border: none;
  outline: none;
}

.email,
.passi,
.loginB,
.register,
.emailR,
.passiR,
.loginBR {
  padding: 40px 10px;
}

#emailI,
#emailIR {
  padding: 10px 20px 10px 20px;
  width: 34em;
  color: rgb(0, 5, 36);
  font-size: 24px;
  border-radius: 50px;
  border: none;
}

#emailI::placeholder,
#passI::placeholder,
#registerI::placeholder,
#emailIR::placeholder,
#passIR::placeholder {
  color: #A7C957;
  font-weight: bolder;
}

#passI,
#passIR {
  padding: 10px 20px 10px 20px;
  width: 15.5em;
  color: rgb(0, 5, 36);
  font-size: 24px;
  border-radius: 50px;
  border: none;
  font-weight: bolder;
}

#passI:focus,
#passIR:focus {
  outline: none;
}

#loginBI,
#loginBIR {
  padding: 10px 20px 10px 20px;
  width: 34em;
  color: #A7C957;
  font-size: 24px;
  border-radius: 50px;
  border: none;
  background-color: rgb(0, 5, 36);
  transition: 0.9s;
  cursor: pointer;
  font-weight: bolder;
}

#loginBI:hover {
  background-color: #BC4749;
  transition: 0.9s;
  color: white;
}

#loginBI:disabled {
  cursor: not-allowed;
  background-color: black;
  transition: 0.9s;
}

.loginOptions{
width: 100%;
height: fit-content;
display: flex;
justify-content: center;
}

.loginOptions span{
color: rgb(0, 5, 36);
}

#eye{
  position: absolute;
  color: #A7C957;
  cursor: pointer;
  margin-left: 24%;
  margin-top: 1.5%;
}

/* fin Login */


/* Register */
.register {

  padding: 5px;
  height: 70px;
  
  
}

.registerForm {
  padding-top: -20px;
  height: 550px;
  
}

.labelR {
  margin-top: 10px;
  margin-bottom: 0px;
  
}

#registerI {
  padding: 0px 10px 0px 10px;
  width: 34em;
  color: rgb(0, 5, 36);
  font-size: 24px;
  border-radius: 50px;
  border: none;
 
}

.labelER {
  margin-top: 0px;
  margin-bottom: 0px;
}

.labelPR {
  margin-top: 0px;
  margin-bottom: 0px;
}

.emailR {
  padding: 10px;
}

.passiR {
  padding: 10px;
}

.loginBR {
  padding: 30px 20px;
}

#registerI {
  width: 34em;
  
}

#loginBIR {
  width: 34em;
  background-color: rgb(0, 5, 36);
  transition: 0.9s;
  font-weight: bolder;
}

#loginBIR:hover {
  background-color: #BC4749;
  transition: 0.9s;
  color: white;
}



/* Fin de Register */


/* About */

.aboutO{
  width:100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.aboutPic{
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutPic h1{
  position: absolute;
  margin-top: 50px;
  font-size: 50px;
  color: white;
}

#imgAbout{
  width: 100%;
  height: 300px;
}

.aboutIn{
  display: flex;
  width: 100%;
  height: fit-content;
  column-gap: 3%;
  padding-left: 50px;
  padding-top: 50px;
}

.aboutIn2{
  display: flex;
  width: 95%;
  height: fit-content;
  padding: 50px 50px;
  border-right: 4px solid #A7C957;
  border-left: 4px solid #A7C957;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 4px 4px 7px 2px #A7C957;
  
}


.aboutIn2 h1{
  margin-top: 60px;
  color: #BC4749;
  font-weight: bolder;
}

.aboutIn2 p{
  color: rgb(0, 5, 36);
  font-weight: bolder;
}

/* photop */
.photoCredict{
  margin-top:25px;
  display: flex;
  height: fit-content;
  align-items: center;
  width:100%;
  flex-direction: column;
  justify-content: center;
}

.photoP img{
  border-radius: 17px;
}

#iconpp{
  color:#A7C957;
  transition: all 0.5s;
}

#iconpp:hover{
  color:#BC4749;
  transition: all 0.5s;

}



/* Fin de About */

/* Dashboard Admin */
.dashO{
  width: 100%;
  height: fit-content;
  display: flex;
}

.dashMenu{
  width: 17%;
  height: 100vh;
  background-color: rgb(0, 5, 36);
  color: white;
  padding-top: 50px;
  position: fixed;
  overflow-y: scroll;
}

.dashMenuIn,.dashMenuIn2,.dashMenuIn3{
  width: 100%;
  display: flex;
  justify-content: center;
align-content: center;
margin-top: 20px;
cursor: pointer;
color: #A7C957;

}

.dashMenuIn:hover,.dashMenuIn2:hover,.dashMenuIn3:hover{
background-color: #BC4749;
color: white;
}

.dashMenu h3,.dashMenu2 h3,.dashMenu3 h3{
padding: 10px;

}
.dashMenu:hover,.dashMenu2:hover,.dashMenu3:hover{

color: white;
}


.dashContent{
  width: 90%;
  height: fit-content;
  padding: 40px 50px;
  margin-left:180px ;
  text-align: center;
  
}

.dashUserO{
  width: 100%;
  height: 100%;
  color: rgb(0, 5, 36);
}

.contentUser table,.contentUser2 table{
  width: 90%;
  height: fit-content;
  
  margin-bottom: 20px;
}

.contentUser{
border-radius: 20px;
  box-shadow: 4px 4px 7px 2px rgb(0, 5, 36);
}



.contentUser2 table{
 width: 100%;
 border: none;
}

.contentUser2{
border-radius: 20px;
  box-shadow: 4px 4px 7px 2px rgb(0, 5, 36);
  margin: 70px 0px 70px 0px;
}


.contentUser th,.contentUser2 th{
  border-bottom: 3px solid #BC4749;
  font-size: 20px;
  font-weight: bolder;
}

.contentUser td,.contentUser2 td{
  text-align: center;
  padding: 10px;
  font-weight: bolder;
}

#dashContentBtn{
  border: none;
  padding: 5px;
  color: white;
  background-color: #BC4749;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s;
}

#dashContentBtn2{
  border: none;
  padding: 5px;
  color: white;
  background-color: #A7C957;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s;
}

#dashContentBtn:hover{
  color: white;
  background-color: rgb(0, 5, 36);
  transition: all 0.5s;
}

#dashContentBtn2:hover{
  color: white;
  background-color: rgb(0, 5, 36);
  transition: all 0.5s;
}


.contentUser h1,.contentUser2 h1{
  text-align: center;
  margin-bottom: 20px;
  color: #BC4749;
  font-weight: bolder;
}
.contentUser h3,.contentUser2 h3{
  text-align: center;
  margin-bottom: 20px;
  color: #BC4749;
  font-weight: bolder;
}

.addCulture{

  margin-left: 30%;
  margin-bottom: 20px;
}

.addCulture input{
  margin-right: 10px;
  border: 2px solid #A7C957;
  font-size: 20px;
  outline: none;
  font-weight: bolder;
  color: rgb(0, 5, 36);
  padding: 2px 15px;
}


#imagetop{
  width: 500px;
  height: 300px;
}

/* Fin de Dashboard Admin  */



/* Agriculture */
.payO{
  width: 100%;
  height: fit-content;
  margin-bottom: 50px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 10px;

}

.payIn{
  width: 100%;
  height: fit-content;
 
 text-align: center;
}

.imgPay{
margin-bottom: 15px;
margin-top: 20px;
}

#imgPay{
  width: 150px;
  height: 150px;
}


#btn-pay{
  padding: 15px 40px;
  color: white;
  background-color: #A7C957;
  outline: none;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.4s;
  margin-top: 15px;
  margin-bottom: 10px;
}

#btn-pay:hover{
  background-color: #BC4749;
  transition: all 0.4s;
}




/* fin de Agriculture */




.space{
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
}

/* Media Queries */

/* MEDIA QUERIES, Medium Devices at 280px */
@media only screen and (min-width:50px) {
/* Headers */

#eye2{
  position: absolute;
  color: #A7C957;
  cursor: pointer;
  margin-left: 80%;
  margin-top: 2%;
  font-size: 0.8em;
}

.logo{
  width: 100%;
  column-gap: 2%;
  margin-left: 2%;
 
}


.logoVPhoto2{
  background-image: url("./components/favicon-32x32.png");
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;

}


.userName{
  display: none;
  color: white;
}

#userString{
  display: none;
}

.logoN h2{
font-size: 16px;
}

.navBar{
  column-gap: 0%;
}

.menu{
  width: 30%;
  
}

.menuOpen{
  width: 100vw;
  
}

#menuBar,#menuBarClose{
  font-size: 12px;
}

.menuOpen a{
  font-size: 13px ;
}



/* Cover */

.slider{
  width: 100%;
  height: 200px;
  
}

.photoCover{
  height:200px;
}

.infosCover h1{
font-size: 35px;
margin-top: 2px;
margin-left: 4%;
}

.infosCover p{
  font-size: 15px;
  font-weight: bolder;
  margin-left: 4%;
}

#arrowRight,#arrowLeft{
  display: none;
}

/* MainPOST */

.postIn{
  width: 100%;
}

.promoSubscribe{
  margin-bottom: 50px;
  flex-direction: column;
}
.agri {
  width: 100%;
  height: fit-content;
    margin: 20px 0px;
    margin-bottom: 50px;

}

.ache {
  width: 100%;
  height: fit-content;
    margin: 20px 0px;
   

}


.postO2{
  flex-direction: column;
} 

.singleFirst{
  
  column-gap: 0px;
  margin-top: 10px;
  margin-left: 0px;
  
}


.singleSecond{
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
}

#protext1{
 
  font-size: 30px;
 
}

#protext1t{
 
  font-size: 18px;
  color: #BC4749;
 
}
.post{
  width: 92%;
  height: 375px;
}

.postPhoto{
  height: 200px;
}

.photoPost{
  height: 200px;
}

.postInfos{
  height: fit-content;
}

.postInfos h1{
font-size: 22px;
}

.postReserv{
height: fit-content;

}

.postReserv h2{
  font-size: 15px;
}



.sideBarO{
  display: none;
}

/* Map */

#mapId2 {
  width: 85vw;
  height: 70vh;
  z-index: 0;
}

.mapContainer{
  margin-top: 100px;
  width: 100%;
  height: 500px;
margin-bottom: 200px; 
}



/* Footer */
.footerO{
  height: fit-content;
  
  
}

.footerIn{
  flex-direction: column;
}

.typeFooter{
  display: flex;
  justify-content: center;
  
}

.product{
  width: 100%;
  padding: 0px 2%;
  column-gap: 3%;
margin-top: 30px;
}

.infos{
  width: 100%;
  justify-content: center;
  text-align: center;
}

.infosHead{
 
   width: 100%;
   text-align: center;
}

.credit{
 
  flex-direction: column;
  height: fit-content;
  padding: 0px 10px;
}



/* Single Post */


.singlePhoto{
  height: 200px;
}

.infosSingle{
  width: 100%;
}

.infosClient{
  
  height:fit-content;
}


.infosSingle{
  margin-top: 50px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
 
  
}

.singlePostIn{
  
  padding-left: 2px;
  padding-right: 2px;
  
}

.infosFixe{
 margin-right: 20px;
}




#nameC,#quartierC,#numberCl{
  font-size: 20px;
  width: 13em;
 
  
}

#villeIn{
width: 13em;
font-size: 20px;
}

.infosUser{
  margin-top: 10px;
  flex-direction: column;
  text-align: center;
  height: fit-content;
 
}

.infoOne,.infoTwo{
  /* width: 100%; */
}

#dashContentBtn3{
  border: none;
  padding: 2px;
  color: white;
  background-color: rgb(0, 5, 36);
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s;
  margin-left: 4%;
}


/* Publish */

.publishO{
  height: fit-content;
}

#asterix{
  font-size: 20px;
}

.datePicker{
  margin-left: 10%;
}

#inputImage{
margin-left: 10%;
}

.previewImage{
  width: 280px;
  height: 200px;
}

/* Map */



#mapId{
  width: 90vw;
}

.popupO{
  width: 180px;
  height: fit-content;
}

.popImage{
   width: 180px;
  height: 100px;
}

.popupOne h2{
  font-size: 13px;
}


/* Agriculteurs */

#eye3 {
 
  margin-left: -15%;
  margin-top: 21px;
 
}



/* LOGIN */

.loginForm{
  
   width: 270px;
}

#emailI{
  padding: 10px 20px 10px 20px;
  width: 24em;
  font-size: 18px;
  
}

#passI,
#passIR {
  padding: 10px 10px;
  width: 14em;
  font-size: 18px;
  
}

.loginOptions span{
  font-size: 17px;
}
.loginOptions h3{
  font-size: 17px;
}

#emailI::placeholder,
#passI::placeholder,
#registerI::placeholder,
#emailIR::placeholder,
#passIR::placeholder {
  font-size: 17px;
}

#eye{
  
  margin-left: 70%;
  margin-top: 4%;
}

/* Register */

.register{
  height: fit-content;
}

.registerForm{
  height: fit-content;
  width: 280px;
  padding-bottom: 5px;
}

#registerI {
  padding: 0px 10px 0px 10px;
  width: 13em;
  font-size: 20px;
 
}

#loginBIR {
  width: 11em; 
  padding: 5px; 
}

#emailIR {
  padding: 5px 5px;
  width: 13em;
  font-size: 20px;
  
}

/* About */

.aboutPic h1{
  margin-top: 10px;
  font-size: 30px;
  
}

#imgAbout{
  height: 200px;
}

.aboutIn{
  height: fit-content;
  column-gap: 0%;
  padding-left: 0px;
  padding-top: 20px;
  margin-bottom: 30px;
}

.aboutIn2{
  display: flex;
  width: 95%;
  height: fit-content;
  padding: 50px 10px;
  padding-right: 5px;
  padding-left: 10px;
  padding-top: 30px;
  
}

/*LOADING */
.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

/* Admin */



}


/* MEDIA QUERIES, Medium Devices at 280px */
@media only screen and (min-width:360px) {

#eye{
  
  margin-left: 60%;
  margin-top: 3%;
}

#eye2{
  
  margin-left: 60%;
  margin-top: 4%;
  
}

.logo{
  width: 70%;
  column-gap: 2%;
  margin-left: 2%;
}

.logoV h2{
  font-size: 15px;
}


.userName{
  display: none;
}

#userString{
  display: none;
}

.logoN h2{
font-size: 16px;
}

.navBar{
  column-gap: 0%;
}

.menu{
  width: 30%;
  
}

.menuOpen{
  width: 100vw;
  
}

#menuBar,#menuBarClose{
  font-size: 14px;
}

.menuOpen a{
  font-size: 15px ;
}

/* Cover */

.slider{
  width: 100%;
  height: 200px;
  
}

.photoCover{
  height:200px;
}

.infosCover h1{
font-size: 35px;
margin-top: 2px;
margin-left: 4%;
}

.infosCover p{
  font-size: 15px;
  font-weight: bolder;
  margin-left: 4%;
}

#arrowRight,#arrowLeft{
  display: none;
}

/* MainPOST */

.postIn{
  width: 100%;
}


.agri {
  width: 95%;
  height: 320px;
  margin: 20px auto;
  margin-bottom: 50px;

}

.ache {
  width: 95%;
  height: 320px;
  margin: 20px auto;


}


.singleSecond{
  width: 95%;
  margin-left: 10px;
}

.post{
  width: 75%;
  height: 375px;
  margin-left: 13%;
}

.postPhoto{
  height: 200px;
 
}

.photoPost{
  height: 200px;
}

.postInfos{
  height: fit-content;
}

.postInfos h1{
font-size: 22px;
}

.postReserv{
height: fit-content;

}

.postReserv h2{
  font-size: 15px;
}



.sideBarO{
  display: none;
}

/* Map */

#mapId2 {
  width: 90vw;
  height: 70vh;
  z-index: 0;
}

.mapContainer{
  margin-top: 100px;
  width: 100%;
  height: 400px;
  margin-bottom: 300px;
 
}



/* Footer */
.footerO{
  height: fit-content;
}

.footerIn{
  flex-direction: column;
}

.typeFooter{
  display: flex;
  justify-content: center;
  
}

.product{
  width: 100%;
  padding: 0px 2%;
  column-gap: 3%;
margin-top: 30px;
}

.infos{
  width: 100%;
  justify-content: center;
  text-align: center;
}

.infosHead{
 
   width: 100%;
   text-align: center;
}

/* Single Post */

.singlePhoto{
  height: 200px;
}

.infosSingle{
  width: 100%;
}

.infosClient{
  
  height:fit-content;
}


.singlePostIn{
  
  padding-left: 4%;
  padding-right: 4%;
  
}

#nameC,#quartierC,#numberCl{
  font-size: 20px;
  width: 16em;
 
}

#villeIn{
width: 16em;
font-size: 20px;
}

.infosUser{
  margin-top: 10px;
  flex-direction: column;
  text-align: center;
  height: fit-content;
 
}

.infoOne,.infoTwo{
  /* width: 100%; */
}

/* Publish */

.publishO{
  height: fit-content;
}

#asterix{
  font-size: 20px;
}

.datePicker{
  margin-left: 10%;
}

#inputImage{
margin-left: 10%;
}

.previewImage{
  width: 340px;
  height: 200px;
}

/* Map */

#mapId{
  width: 90vw;
}

.popupO{
  width: 260px;
  height: fit-content;
}

.popImage{
   width: 260px;
  height: 100px;
}

.popupOne h2{
  font-size: 16px;
}

.popupOne{
  text-align: center;
}

#btnPopSupr,#btnPopSupr2{
  margin-left: 2%;
}

/* LOGIN */

.loginForm{
  
   width: 300px;
}

#emailI{
  padding: 10px 20px 10px 20px;
  width: 24em;
  font-size: 18px;
  
}

#passI,
#passIR {
  padding: 10px 10px;
  width: 15em;
  font-size: 18px;
}

.loginOptions span{
  font-size: 17px;
}
.loginOptions h3{
  font-size: 17px;
}

/* Register */

.register{
  height: fit-content;
}

.registerForm{
  height: fit-content;
  width: 300px;
  padding-bottom: 5px;
}

#registerI {
  padding: 10px 10px 0px 10px;
  width: 13em;
  font-size: 20px;
  
 
}

#loginBIR {
  width: 11em; 
  padding: 5px; 
  
}

#emailIR {
  padding: 5px 5px;
  width: 13em;
  font-size: 20px;
  
}

#emailI::placeholder,
#passI::placeholder,
#registerI::placeholder,
#emailIR::placeholder,
#passIR::placeholder {
  font-size: 17px;
}

/* About */

.aboutPic h1{
  margin-top: 10px;
  font-size: 30px;
  
}

#imgAbout{
  height: 200px;
}

.aboutIn{
  height: fit-content;
  column-gap: 0%;
  padding-left: 10px;
  padding-top: 20px;
  margin-bottom: 30px;
}

#eye3 {

  margin-left: -6%;
  
}


}


/* MEDIA QUERIES, Medium Devices at 480px */
@media only screen and (min-width:480px) {

#eye{
  
  margin-left: 46%;
  margin-top: 2%;
}

#eye2{
  
  margin-left: 46%;
  margin-top: 3%;
  
}

.navBar{
  
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.menu{
 display: flex;
  flex-direction: row;
  width: 60%;
  column-gap: 1%;
  justify-content: center;
}

.logo{
  width: 40%;
  
}

.logoV h2{
  font-size: 15px;
  width: 100%;
  
}


.userName{
  display: flex;
 color: #A7C957;
  margin-right: 0%;
  display: flex;
  flex-direction: row;
  width: 85%;
  align-self: center;
  justify-content: center;
  
}

#userString{
  border: 1px solid #A7C957;
  border-radius: 15px;
  padding: 2px;
  cursor: pointer;
  font-weight: bolder;
  transition: all 0.5s;
  display: inline-block;
}

#userString:hover{
  color: white;
  transition: all 0.5s;
  background-color: #BC4749;
  border: 1px solid #BC4749;
}

.userName h3{
  margin-left: 6px;
  font-size: 12px;
  
}

.logoN h2{
font-size: 16px;
}

.menuOpen{
  width: 100vw;
  
}

#menuBar,#menuBarClose{
  font-size: 13px;
  margin-top: 10px;
}

.menuOpen a{
  font-size: 15px ;
}

/* Cover */

.slider{
  width: 100%;
  height: 200px;
  
}

.photoCover{
  height:200px;
}

.infosCover h1{
font-size: 35px;
margin-top: 2px;
margin-left: 4%;
}

.infosCover p{
  font-size: 15px;
  font-weight: bolder;
  margin-left: 4%;
}

#arrowRight,#arrowLeft{
  display: none;
}

/* MainPOST */

.postIn{
  width: 100%;
}

.post{
  width: 47%;
  height: 375px;
  margin-left: 2%;
}

.postPhoto{
  height: 170px;
}

.photoPost{
  height: 170px;
}

.postInfos{
  height: fit-content;
}

.postInfos h1{
font-size: 22px;
}

.postReserv{
height: fit-content;

}

.postReserv h2{
  font-size: 15px;
}



.sideBarO{
  display: none;
}

/* Map */

#mapId2 {
  width: 91vw;
  height: 70vh;
  z-index: 0;
}

.mapContainer{
  margin-top: 100px;
  width: 100%;
  height: 500px;
  margin-bottom: 200px;
  
 
}



/* Footer */
.footerO{
  height: fit-content;
}

.footerIn{
  flex-direction: column;
}

.typeFooter{
  display: flex;
  justify-content: center;
  
}

.product{
  width: 100%;
  padding: 0px 2%;
  column-gap: 3%;
margin-top: 30px;
}

.infos{
  width: 100%;
  justify-content: center;
  text-align: center;
}

.infosHead{
 
   width: 100%;
   text-align: center;
}

/* Single Post */

.singlePhoto{
  height: 200px;
}

.infosSingle{
  width: 100%;
}

.infosClient{
  
  height:fit-content;
}

#nameC,#quartierC,#numberCl{
  font-size: 20px;
  width: 16em;
 
}

#villeIn{
width: 16em;
font-size: 20px;
}

.infosUser{
  margin-top: 10px;
  flex-direction: column;
  text-align: center;
  height: fit-content;
 
}

.infoOne,.infoTwo{
  /* width: 100%; */
}

/* Publish */

.publishO{
  height: fit-content;
}

#asterix{
  font-size: 20px;
}

.datePicker{
  margin-left: 10%;
}

#inputImage{
margin-left: 10%;
}

.previewImage{
  width: 340px;
  height: 200px;
}

/* Map */

#mapId{
  width: 90vw;
}

.popupO{
  width: 260px;
  height: fit-content;
}

.popImage{
   width: 260px;
  height: 100px;
}

.popupOne h2{
  font-size: 16px;
}

.popupOne{
  text-align: center;
}

#btnPopSupr,#btnPopSupr2{
  margin-left: 2%;
}

/* LOGIN */

.loginForm{
  
   width: 300px;
}

#emailI{
  padding: 10px 20px 10px 20px;
  width: 24em;
  font-size: 18px;
  
}

#passI,
#passIR {
  padding: 10px 10px;
  width: 15em;
  font-size: 18px;
}

.loginOptions span{
  font-size: 17px;
}
.loginOptions h3{
  font-size: 17px;
}

/* Register */

.register{
  height: fit-content;
}

.registerForm{
  height: fit-content;
  width: 300px;
  padding-bottom: 5px;
}

#registerI {
  padding: 10px 10px 0px 10px;
  width: 13em;
  font-size: 20px;
  
 
}

#loginBIR {
  width: 11em; 
  padding: 5px; 
  
}

#emailIR {
  padding: 5px 5px;
  width: 13em;
  font-size: 20px;
  
}

#emailI::placeholder,
#passI::placeholder,
#registerI::placeholder,
#emailIR::placeholder,
#passIR::placeholder {
  font-size: 17px;
}

/* About */

.aboutPic h1{
  margin-top: 10px;
  font-size: 30px;
  
}

#imgAbout{
  height: 200px;
}

.aboutIn{
  height: fit-content;
  column-gap: 0%;
  padding-left: 10px;
  padding-top: 20px;
  margin-bottom: 30px;
}

}


/* MEDIA QUERIES, Medium Devices at 768px */
@media only screen and (min-width:768px) {
.singlePhoto{
  display: flex;
  justify-content: center;
}
.imageSingle{
  margin-top: 50px;
  width: 480px;
  height: 300px;
  background-size: cover;
}

#eye{
  
  margin-left: 29%;
  margin-top: 1.5%;
}

#eye2{
  
  margin-left: 29%;
  margin-top: 2%;
  
}

.navBar{
  
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.menu{
 display: flex;
  flex-direction: row;
  width: 60%;
  column-gap: 1%;
  justify-content: center;
}

.logo{
  width: 40%;
  
}

.logoN a h2{
  font-size: 22px;
}

.logoV h2{
  font-size: 15px;
  width: 100%;
  
}

.userName{
  display: flex;
 color: #A7C957;
  margin-right: 0%;
  display: flex;
  flex-direction: row;
  width: 85%;
  align-self: center;
  justify-content: center;
  
}

#userString{
  border: 1px solid #A7C957;
  border-radius: 15px;
  padding: 5px;
  cursor: pointer;
  font-weight: bolder;
  transition: all 0.5s;
  display: inline-block;
}

#userString:hover{
  color: white;
  transition: all 0.5s;
  background-color: #BC4749;
  border: 1px solid #BC4749;
}

.userName h3{
  margin-left: 6px;
  font-size: 16px;
  
}

.logoN h2{
font-size: 16px;
}

.menuOpen{
  width: 100vw;
  
}

#menuBar,#menuBarClose{
  font-size: 13px;
  margin-top: 10px;
}

.menuOpen a{
  font-size: 15px ;
}

/* Cover */

.slider{
  width: 100%;
  height: 300px;
  
}

.photoCover{
  height:300px;
}

.infosCover h1{
font-size: 35px;
margin-top: 2px;
margin-left: 4%;
}

.infosCover p{
  font-size: 15px;
  font-weight: bolder;
  margin-left: 4%;
}

#arrowRight,#arrowLeft{
  display: none;
}

/* MainPOST */

.postIn{
  width: 100%;
}

.postO2{
  flex-direction: row;
} 

.singleSecond{
  width: 100%;
  margin-top: 20px;
  font-size: 20px;
}

#protext1{
 
  font-size: 43px;
 
}

#protext1t{
 
  font-size: 30px;
 
}
.post{
  width: 45%;
  height: 375px;
  margin-left: 3%;
}

.postPhoto{
  height: 200px;
}

.photoPost{
  height: 200px;
}

.postInfos{
  height: fit-content;
}

.postInfos h1{
font-size: 22px;
}

.postReserv{
height: fit-content;

}

.postReserv h2{
  font-size: 15px;
}

/* SideBar */

.sideBarO{
  width: 30%;
  height: fit-content;
  background-color: rgb(0, 5, 36);
  display: flex;
  padding:30px 2%;
  color:white;
  margin-top: 50px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.sideBarIn{
  width: 100%;
  height:100%;
}

.sideBarIn h1{
  color:#BC4749;
  font-size: 24px;
}

.typeCulture{
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 40px;
  justify-content: center;
  
}

.typeCulture li h3{
  margin-bottom: 20px;
  cursor: pointer;
  color: #A7C957;
  transition: all 0.5s;
}
.typeCulture li h3:hover{
  color:white;
  transition: all 0.5s;
}


/* Map */

#mapId2 {
  width: 91vw;
  height: 70vh;
  z-index: 0;
}

.mapContainer{
  margin-top: 100px;
  width: 100%;
  height: 500px; 
  margin-bottom: 250px;
}



/* Footer */
.footerO{
  height: fit-content;
}

.footerIn{
  flex-direction: row;
}

.typeFooter{
  display: flex;
  justify-content: center;
  
}

.product{
  width: 100%;
  padding: 0px 2%;
  column-gap: 3%;
margin-top: 30px;
}

.infos{
  width: 100%;
  justify-content: center;
  text-align: center;
  margin-top: 12px;
  padding-right: 30px;
}

.infosHead{
 
   width: 100%;
   text-align: center;
}

.credit{
 
  flex-direction: row;
  height: 25px;
  padding: 0px 10px;
}

/* Single Post */

.singlePhoto{
  height: 200px;
}

.infosSingle{
  width: 100%;
}

.infosClient{
  
  height:fit-content;
}

.infosSingle{
  margin-top: 0px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
 margin-right: 50px;
}

.singlePostIn{
  
  padding-left: 4%;
  padding-right: 0%;
  
}

.singlePhoto{
  display: flex;
  width: 100%;
  height: 400px;
  
}


#nameC,#quartierC,#numberCl{
  font-size: 20px;
  width: 16em;
 
}

#villeIn{
width: 16em;
font-size: 20px;
}

.infosUser{
  margin-top: 10px;
  flex-direction: column;
  text-align: center;
  height: fit-content;
 
}

.infoOne,.infoTwo{
  /* width: 100%; */
}

/* Publish */

.publishO{
  height: fit-content;
}

#asterix{
  font-size: 20px;
}

.datePicker{
  margin-left: 10%;
}

#inputImage{
margin-left: 10%;
}

.previewImage{
  width: 340px;
  height: 200px;
}

/* Map */

#mapId{
  width: 90vw;
}

.popupO{
  width: 260px;
  height: fit-content;
}

.popImage{
   width: 260px;
  height: 100px;
}

.popupOne h2{
  font-size: 16px;
}

.popupOne{
  text-align: center;
}

#btnPopSupr,#btnPopSupr2{
  margin-left: 2%;
}

/* LOGIN */

.loginForm{
  
   width: 300px;
}

#emailI{
  padding: 10px 20px 10px 20px;
  width: 24em;
  font-size: 18px;
  
}

#passI,
#passIR {
  padding: 10px 10px;
  width: 15em;
  font-size: 18px;
}

.loginOptions span{
  font-size: 17px;
}
.loginOptions h3{
  font-size: 17px;
}

/* Register */

.register{
  height: fit-content;
}

.registerForm{
  height: fit-content;
  width: 300px;
  padding-bottom: 5px;
}

#registerI {
  padding: 10px 10px 0px 10px;
  width: 13em;
  font-size: 20px;
  
 
}

#loginBIR {
  width: 11em; 
  padding: 5px; 
  
}

#emailIR {
  padding: 5px 5px;
  width: 13em;
  font-size: 20px;
  
}

#emailI::placeholder,
#passI::placeholder,
#registerI::placeholder,
#emailIR::placeholder,
#passIR::placeholder {
  font-size: 17px;
}

/* About */

.aboutPic h1{
  margin-top: 10px;
  font-size: 30px;
  
}

#imgAbout{
  height: 200px;
}

.aboutIn{
  height: fit-content;
  column-gap: 2%;
  padding-left: 10px;
  padding-top: 20px;
  margin-bottom: 30px;
}


#eye3 {

  margin-left: -3%;
  

}

}


/* MEDIA QUERIES, Medium Devices at 820px */

@media only screen and (min-width:820px) {

#eye2{
  
  margin-left: 26%;
  margin-top: 2%;
  
}

.navBar{
  
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.menu{
 display: flex;
  flex-direction: row;
  width: 60%;
  column-gap: 1%;
  justify-content: center;
}

.logo{
  width: 40%;
  
}

.logoN a h2{
  font-size: 22px;
}

.logoV h2{
  font-size: 15px;
  width: 100%;
  
}


.userName{
  display: flex;
 color: #A7C957;
  margin-right: 0%;
  display: flex;
  flex-direction: row;
  width: 85%;
  align-self: center;
  justify-content: center;
  
}

#userString{
  border: 1px solid #A7C957;
  border-radius: 15px;
  padding: 5px;
  cursor: pointer;
  font-weight: bolder;
  transition: all 0.5s;
  display: inline-block;
}

#userString:hover{
  color: white;
  transition: all 0.5s;
  background-color: #BC4749;
  border: 1px solid #BC4749;
}

.userName h3{
  margin-left: 6px;
  font-size: 16px;
  
}

.logoN h2{
font-size: 16px;
}

.menuOpen{
  width: 100vw;
  
}

#menuBar,#menuBarClose{
  font-size: 13px;
  margin-top: 10px;
}

.menuOpen a{
  font-size: 15px ;
}

/* Cover */

.slider{
  width: 100%;
  height: 300px;
  
}

.photoCover{
  height:300px;
}

.infosCover h1{
font-size: 35px;
margin-top: 2px;
margin-left: 4%;
}

.infosCover p{
  font-size: 15px;
  font-weight: bolder;
  margin-left: 4%;
}

#arrowRight,#arrowLeft{
  display: none;
}

/* MainPOST */

.postIn{
  width: 100%;
}

.singleFirst{
  display: flex;
  column-gap: 50px;
  width: 360px;
  height: 500px;
  margin-top: 50px;
  margin-left: 30px;
  
}

.singleSecond{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 500px;
  height: fit-content;
  margin-top: 50px;
  font-size: 20px;
  background-color: rgb(0, 5, 36);
  color: white;
  padding: 0px 15px;
  border-radius: 20px;
  box-shadow: 4px 4px 7px 2px #BC4749;
  padding: 80px 10px;
 

}

.post{
  width: 45%;
  height: 375px;
  margin-left: 3%;
}

.postPhoto{
  height: 200px;
}

.photoPost{
  height: 200px;
}

.postInfos{
  height: fit-content;
}

.postInfos h1{
font-size: 22px;
}

.postReserv{
height: fit-content;

}

.postReserv h2{
  font-size: 15px;
}

/* SideBar */

.sideBarO{
  width: 30%;
  height: fit-content;
  background-color: rgb(0, 5, 36);
  display: flex;
  padding:30px 2%;
  color:white;
  margin-top: 50px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.sideBarIn{
  width: 100%;
  height:100%;
}

.sideBarIn h1{
  color:#BC4749;
  font-size: 24px;
}

.typeCulture{
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 40px;
  justify-content: center;
  
}

.typeCulture li h3{
  margin-bottom: 20px;
  cursor: pointer;
  color: #A7C957;
  transition: all 0.5s;
}
.typeCulture li h3:hover{
  color:white;
  transition: all 0.5s;
}


/* Map */

#mapId2 {
  width: 91vw;
  height: 70vh;
  z-index: 0;
}

.mapContainer{
  margin-top: 100px;
  width: 100%;
  height: 500px; 
  margin-bottom: 400px;
}



/* Footer */
.footerO{
  height: fit-content;
}

.footerIn{
  flex-direction: row;
}

.typeFooter{
  display: flex;
  justify-content: center;
  
}

.product{
  width: 100%;
  padding: 0px 2%;
  column-gap: 3%;
margin-top: 30px;
}

.infos{
  width: 100%;
  justify-content: center;
  text-align: center;
  margin-top: 12px;
  padding-right: 30px;
}

.infosHead{
 
   width: 100%;
   text-align: center;
}

/* Single Post */

.singlePhoto{
  height: 200px;
}

.infosSingle{
  width: 100%;
}

.infosClient{
  
  height:fit-content;
}

.singlePhoto{
  display: flex;
  width: 100%;
  height: 400px;
  
}


#nameC,#quartierC,#numberCl{
  font-size: 20px;
  width: 16em;
 
}

#villeIn{
width: 16em;
font-size: 20px;
}

.infosUser{
  margin-top: 10px;
  flex-direction: column;
  text-align: center;
  height: fit-content;
 
}

.infoOne,.infoTwo{
  /* width: 100%; */
}

/* Publish */

.publishO{
  height: fit-content;
}

#asterix{
  font-size: 20px;
}

.datePicker{
  margin-left: 10%;
}

#inputImage{
margin-left: 10%;
}

.previewImage{
  width: 340px;
  height: 200px;
}

/* Map */

#mapId{
  width: 90vw;
}

.popupO{
  width: 260px;
  height: fit-content;
}

.popImage{
   width: 260px;
  height: 100px;
}

.popupOne h2{
  font-size: 16px;
}

.popupOne{
  text-align: center;
}

#btnPopSupr,#btnPopSupr2{
  margin-left: 2%;
}

/* LOGIN */

.loginForm{
  
   width: 300px;
}

#emailI{
  padding: 10px 20px 10px 20px;
  width: 24em;
  font-size: 18px;
  
}

#passI,
#passIR {
  padding: 10px 10px;
  width: 15em;
  font-size: 18px;
}

.loginOptions span{
  font-size: 17px;
}
.loginOptions h3{
  font-size: 17px;
}

/* Register */

.register{
  height: fit-content;
}

.registerForm{
  height: fit-content;
  width: 300px;
  padding-bottom: 5px;
}

#registerI {
  padding: 10px 10px 0px 10px;
  width: 13em;
  font-size: 20px;
  
 
}

#loginBIR {
  width: 11em; 
  padding: 5px; 
  
}

#emailIR {
  padding: 5px 5px;
  width: 13em;
  font-size: 20px;
  
}

#emailI::placeholder,
#passI::placeholder,
#registerI::placeholder,
#emailIR::placeholder,
#passIR::placeholder {
  font-size: 17px;
}

/* About */

.aboutPic h1{
  margin-top: 10px;
  font-size: 30px;
  
}

#imgAbout{
  height: 200px;
}

.aboutIn{
  height: fit-content;
  column-gap: 2%;
  padding-left: 10px;
  padding-top: 20px;
  margin-bottom: 30px;
}

}


/*  */
/* MEDIA QUERIES, Medium Devices at 912px */

@media only screen and (min-width:912px) {

  .mapContainer{
  margin-top: 100px;
  width: 100%;
  height: 500px; 
  margin-bottom: 500px;
}


/* Abonnez */

.promoSubscribe {
  flex-direction: row;
}

.agri {
  width: 44%;
}

.ache {
  width: 44%;
}



}

/* MEDIA QUERIES, Medium Devices at 992px */

@media only screen and (min-width:992px) {

  #eye{
  
  margin-left: 32%;
  margin-top: 2%;
}

#eye2{
  
  margin-left: 32%;
  margin-top: 1.5%;
  
}

.navBar{
  
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.menu{
 display: flex;
  flex-direction: row;
  width: 60%;
  column-gap: 1%;
  justify-content: center;
}

.logo{
  width: 40%;
  
}

.logoN a h2{
  font-size: 22px;
}

.logoV h2{
  font-size: 15px;
  width: 100%;
  
}




.userName{
  display: flex;
 color: #A7C957;
  margin-right: 0%;
  display: flex;
  flex-direction: row;
  width: 85%;
  align-self: center;
  justify-content: center;
  
}

#userString{
  border: 1px solid #A7C957;
  border-radius: 15px;
  padding: 5px;
  cursor: pointer;
  font-weight: bolder;
  transition: all 0.5s;
  display: inline-block;
}

#userString:hover{
  color: white;
  transition: all 0.5s;
  background-color: #BC4749;
  border: 1px solid #BC4749;
}

.userName h3{
  margin-left: 6px;
  font-size: 16px;
  
}

.logoN h2{
font-size: 16px;
}

.menuOpen{
  width: 100vw;
  
}

#menuBar,#menuBarClose{
  font-size: 13px;
  margin-top: 10px;
}

.menuOpen a{
  font-size: 15px ;
}

/* Cover */

.slider{
  width: 100%;
  height: 400px;
  
}

.photoCover{
  height:400px;
}

.infosCover h1{
font-size: 35px;
margin-top: 2px;
margin-left: 4%;
}

.infosCover p{
  font-size: 15px;
  font-weight: bolder;
  margin-left: 4%;
}

#arrowRight,#arrowLeft{
  display: none;
}

/* MainPOST */

.postIn{
  width: 100%;
}


.agri {
  width: 46%;
}

.ache {
  width: 46%;
}

.post{
  width: 30%;
  height: 410px;
  margin-left: 2%;
}

.postPhoto{
  height: 200px;
}

.photoPost{
  height: 200px;
}

.postInfos{
  
  height: 170px;
}

.postInfos h1{
font-size: 22px;
}

.postReserv{
height: fit-content;

}

.postReserv h2{
  font-size: 15px;
}

/* SideBar */

.sideBarO{
  width: 30%;
  height: fit-content;
  background-color: rgb(0, 5, 36);
  display: flex;
  padding:30px 2%;
  color:white;
  margin-top: 50px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.sideBarIn{
  width: 100%;
  height:100%;
}

.sideBarIn h1{
  color:#BC4749;
  font-size: 24px;
}

.typeCulture{
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 40px;
  justify-content: center;
  
}

.typeCulture li h3{
  margin-bottom: 20px;
  cursor: pointer;
  color: #A7C957;
  transition: all 0.5s;
}
.typeCulture li h3:hover{
  color:white;
  transition: all 0.5s;
}


/* Map */

#mapId2 {
  width: 91vw;
  height: 70vh;
  z-index: 0;
}

.mapContainer{
  margin-top: 100px;
  width: 100%;
  height: 500px; 
  margin-bottom: 500px;
}



/* Footer */
.footerO{
  height: fit-content;
}

.footerIn{
  flex-direction: row;
}

.typeFooter{
  display: flex;
  justify-content: center;
  
}

.product{
  width: 100%;
  padding: 0px 2%;
  column-gap: 3%;
margin-top: 30px;
}

.infos{
  width: 100%;
  justify-content: center;
  text-align: center;
  margin-top: 12px;
  padding-right: 30px;
}

.infosHead{
 
   width: 100%;
   text-align: center;
}

/* Single Post */

.singlePhoto{
  height: 400px;
}

.infosSingle{
  width: 100%;
}

.infosClient{
  
  height:fit-content;
}

#nameC,#quartierC,#numberCl{
  font-size: 20px;
  width: 16em;
 
}

#villeIn{
width: 16em;
font-size: 20px;
}

.infosUser{
  margin-top: 10px;
  flex-direction: column;
  text-align: center;
  height: fit-content;
 
}

.infoOne,.infoTwo{
  /* width: 100%; */
}

/* Publish */

.publishO{
  height: fit-content;
}

#asterix{
  font-size: 20px;
}

.datePicker{
  margin-left: 10%;
}

#inputImage{
margin-left: 10%;
}

.previewImage{
  width: 340px;
  height: 200px;
}

/* Map */

#mapId{
  width: 90vw;
}

.popupO{
  width: 260px;
  height: fit-content;
}

.popImage{
   width: 260px;
  height: 100px;
}

.popupOne h2{
  font-size: 16px;
}

.popupOne{
  text-align: center;
}

#btnPopSupr,#btnPopSupr2{
  margin-left: 2%;
}

/* LOGIN */

.loginForm{
   width: 400px;
   height: fit-content;
   padding-bottom: 1px;
}

#emailI{
  padding: 15px 15px;
  width: 20em;
  font-size: 24px;
  
}

#passI,
#passIR {
  padding: 15px 15px;
  width: 15.5em;
  font-size: 24px;
}

.loginOptions span{
  font-size: 17px;
  
}
.loginOptions h3{
  font-size: 17px;
  margin-top: -20px;
}

/* Register */

.register{
  height: fit-content;
}

.registerForm{
  height: fit-content;
  width: 400px;
  padding-bottom: 1px;
}

#registerI {
  padding: 10px 10px;
  width: 18em;
  font-size: 20px;
  
 
}

#loginBIR {
  width: 15em; 
  padding: 10px; 
  
}

#emailIR {
  padding: 15px 15px;
  width: 18em;
  font-size: 20px;
  
}

#emailI::placeholder,
#passI::placeholder,
#registerI::placeholder,
#emailIR::placeholder,
#passIR::placeholder {
  font-size: 17px;
}

/* About */

.aboutPic h1{
  margin-top: 10px;
  font-size: 30px;
  
}

#imgAbout{
  height: 400px;
}

.aboutIn{
  height: fit-content;
  column-gap: 2%;
  padding-left: 10px;
  padding-top: 20px;
  margin-bottom: 30px;
}

}


/* * MEDIA QUERIES, Medium Devices at 1024px */
@media only screen and (min-width:1024px) {

.infosCover h1{
font-size: 65px;
margin-top: 20px;
margin-left: 10%;
}

.infosCover p{
  font-size: 30px;
  font-weight: bolder;
  margin-left: 4%;
}


#arrowRight,#arrowLeft{
  display: flex;
}

#arrowLeft{
  margin-left: -580px;
}

#arrowRight{
  margin-left: 550px;
}

.mapContainer{
  margin-top: 100px;
  width: 100%;
  height: 500px; 
  margin-bottom: 300px;
}

.post{
  width: 30%;
  height: 410px;
  margin-left: 2%;
}

/* Main content */

.agri {
  width: 45%;
}

.ache {
  width: 45%;
}


}

/* MEDIA QUERIES, Medium Devices at 1280px */

@media only screen and (min-width:1280px) {


.photo {
    width: 100%;
    max-height: 400px;
    object-fit: fill;
  }


  #eye{
  
  margin-left: 25%;
  margin-top: 1.5%;
}

#eye2{
  
  margin-left: 25%;
  margin-top: 1.2%;
  
}

#eye3 {

  margin-left: -2%;


}


}